<template>
  <div>

    <CRow>
      <CCol lg="12">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Daftar Penebangan/Pemangkasan Pohon</h4>
            <span style="float:right">
              <!-- <CButton @click="modalAdd=true" color="info" size="sm" style='margin-right:15px'>Form Penebangan</CButton>  -->
              <a href="/#/penebangan/add" class="float-right btn btn-success form-control-sm">Formulir Penebangan</a>
            </span>
          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deletePenebangan }">
          <thead>
            <tr>
              <th>#</th>
              <th>Nama Pemohon</th>
              <th>Alamat Pemohon</th>
              <th>No Telepon</th>
              <th>Alamat Pohon</th>
              <th>Keterangan</th>
              <th>Tanggal Permohonan</th>
              <th style="width:50px">Edit</th>
              <th style="width:50px">Cetak</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  

        </CCardBody>
      </CCard>  
  
      </CCol>
    </CRow>

    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deletePenebangan()" color="danger">Hapus</CButton>
      </template>
    </CModal>

    <!-- <CModal
      title="Form Pengajuan Penebangan"
      color="secondary"
      :show.sync="modalAdd"
    >       
      <CInput
        v-model="no_pengajuan"
        label="Nomor"
        placeholder="Input Nomor"
      /> 
      <CInput
        v-model="jenis_permohonan"
        label="Jenis Permohonan"
        placeholder="Input Jenis Permohonan"
      /> 
      <CInput
        v-model="nama_pemohon"
        label="Nama Pemohon"
        placeholder="Input Nama Pemohon"
      /> 
      <CInput
        v-model="alamat_pemohon"
        label="Alamat Pemohon"
        placeholder="Input Alamat Pemohon"
      /> 
      <CInput
        v-model="email"
        label="Email"
        placeholder="Input Email"
      /> 
      <CInput
        v-model="no_hp"
        label="No HP/Telepon"
        placeholder="Input No HP/Telepon"
      /> 
      <CInput
        v-model="alamat_lokasi_pohon"
        label="Alamat Lokasi Pohon"
        placeholder="Input Alamat Lokasi Pohon"
      /> 
      <CInput
        v-model="alasan"
        label="Alasan"
        placeholder="Input Alasan"
      />        
      <div class="form-group">
        <label>Dokumentasi (Foto/Video)</label><br/>                  
        <div class="custom-file">
          <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile02"
          :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
          <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
          <input type="hidden" v-model="file1">
        </div>
      </div>  
      <CInput
        v-model="tgl_pengajuan"
        type="date"
        label="Tanggal Pengajuan"
        placeholder="Input Tanggal Pengajuan"
      />
      <CInput
        v-model="ket_pengajuan"
        label="Keterangan"
        placeholder="Input Keterangan"
      />     
      <template #footer>
        <CButton @click="modalAdd = false" color="secondary">Batal</CButton>
        <CButton @click="updateFormPengajuan" color="success">Simpan</CButton>
      </template> 
    </CModal> -->

  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
  },
  data: function() {
        return {
            // modalAdd : false,
            // file_dokumentasi : "",
            // file_dokumentasi_text : "",
            // file1 : "",
            placeholder : "Pilih File",
            deleteModal : false,
            idselected:"0",
            products:[],
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },
    confirmDelete: function(id){
      location.href=process.env.VUE_APP_BASE_URL+'penebangan/cetak/'+id
    },
    deletePenebangan: function(){
      // alert(this.idselected);
      axios.delete(process.env.VUE_APP_BASE_URL+"penebangan/"+this.idselected)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {              
          
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"penebangan/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "nama_pemohon" },
                  { "data": "alamat_pemohon" },
                  { "data": "tlp_pemohon", class:"text-center" },
                  { "data": "alamat_pohon" },
                  { "data": "keterangan" },
                  { "data": "tgl_permohonan", class:"text-center" },
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            alert(data.id_penebangan);
                            return "<button @click.prevent='deletePenebangan(`"+data.id_penebangan+"`)' data-id='$r[id_penebangan]' class='btn btn-sm btn-danger btnCetak btn2' >Hapus</button>";
                        }, 
                    },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
       const table = $(this.$refs.table).DataTable({
          // $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"penebangan/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "nama_pemohon" },
                  { "data": "alamat_pemohon" },
                  { "data": "tlp_pemohon", class:"text-center" },
                  { "data": "alamat_pohon" },
                  { "data": "keterangan" },
                  { "data": "tgl_permohonan", class:"text-center" },
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            // alert(data.id_penomoran);
                            return "<button @click.prevent='deletePenebangan(`"+row.id_penebangan+"`)' class='btn btn-sm btn-info btnCetak btn2' >Cetak</button>";
                        }, 
                    },
              ]            
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';

          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnHapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id);
              self.confirmDelete(objdata.id)
          });

          $('tbody', this.$refs.table).on( 'click', '.btnCetak', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id);
              self.confirmDelete(objdata.id)
          });
        // })
      }
      
    }
  }
}
</script>